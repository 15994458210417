import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import competeQuizIcon from "../../assets/images/competeQuiz.png";
import exploreIcon from "../../assets/images/explore.png";
import playGameIcon from "../../assets/images/games/mathmaster/playGame.png";
import puzzleIcon from "../../assets/images/puzzle1.png";
import quizmasterIcon from "../../assets/images/quizManage.png";
import streakIcon from "../../assets/images/streak.png";
import { selectUser } from "../../redux/slices/userSlice";

const QuizTypeSelection = () => {
  const user = useSelector(selectUser);

  return (
    <div className="isolate mb-2 px-6 py-4 bg-gradient-to-t from-purple-200 to-[#FFFDD0] shadow-lg ring-1 ring-black/5 rounded-md flex flex-col items-center justify-center">
      <div className="z-[3] w-full space-y-6">
        <div className="grid grid-cols-2 gap-6 place-items-center">
          {user?.role === "quizmaster" ? (
            <Link
              to={"/quiz/manage/select"}
              className="flex flex-col items-center justify-center"
            >
              <img
                src={quizmasterIcon}
                className="z-[3] h-[64px] animate-scale-fast"
                alt="quizmaster"
              />
              <p className="text-center mt-2 rounded-full font-semibold text-sm">
                Manage Quiz
              </p>
            </Link>
          ) : (
            <>
              <Link
                to={"/quizzes/compete"}
                className="flex flex-col items-center justify-center"
              >
                <img
                  src={competeQuizIcon}
                  className="z-[3] h-[64px] animate-scale-fast"
                  alt="performance"
                />
                <p className="text-center mt-2 rounded-full font-semibold text-sm">
                  Compete Quiz
                </p>
              </Link>

              <Link
                to={"/quizzes/streak"}
                className="flex flex-col items-center justify-center"
              >
                <img
                  src={streakIcon}
                  className="z-[3] animate-scale-fast-2 h-[59px]"
                  alt="streak"
                />
                <p className="text-center mt-2 rounded-full font-semibold text-sm">
                  Streak Challenge
                </p>
              </Link>
            </>
          )}
        </div>
        <div className="grid grid-cols-3 place-items-center gap-6">
          <Link
            to={"/games/puzzles/puzzlemania"}
            className="flex flex-col items-center justify-center"
          >
            <img
              src={puzzleIcon}
              className="animate-scale-fast z-[3] h-[59px]"
              alt="puzzle"
            />
            <p className="text-center mt-2 rounded-full font-semibold text-sm">
              PuzzleKing
            </p>
          </Link>
          <Link
            to={"/quizzes/streak/explore"}
            className="flex flex-col items-center justify-center"
          >
            <img src={exploreIcon} className="z-[3] h-[59px]" alt="explore" />
            <p className="mt-2 text-sm text-center font-semibold rounded-full">
              Explore Quiz
            </p>
          </Link>
          <Link
            to={"/games/maths/mathmaster"}
            className="flex flex-col items-center justify-center"
          >
            <img src={playGameIcon} className="h-[56px]" alt="performance" />
            <p className="text-center mt-2 rounded-full font-semibold text-sm">
              MathMaster
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default QuizTypeSelection;
