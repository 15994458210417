import axios from "axios";
import { config } from "../config";

async function subscribe(serviceWorkerReg, userId) {
  const backendUrl = config.backendUrl;

  const subscription = await serviceWorkerReg.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: config.publicVapidKey,
  });

  await axios.post(`${backendUrl}/api/user/subscribe`, {
    subscription,
    userId: userId,
  });
}

export { subscribe };
