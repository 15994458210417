import "./Bubbles.css";

const Bubbles = () => {
  const texts = [
    { text: "Play", color: "text-white" },
    { text: "Learn", color: "text-[#080BF6]" },
    { text: "Inspire", color: "text-white" },
  ];

  return (
    <div className="bubbles-container html-to-image-ignore">
      {texts.map((text, index) => (
        <div className={`bubble bubble-${index + 1}`} key={index}>
          <span className={`bubble-text font-semibold ${text.color}`}>
            {text.text}
          </span>
        </div>
      ))}
    </div>
  );
};

export default Bubbles;
