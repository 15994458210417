import axios from "axios";
import { getPresignedUrl, getPresignedUrlForDelete } from "../apis/file/file";

const uploadFileToDigitalOcean = async (file, path) => {
  if (!file) {
    throw new Error("No file provided");
  }

  try {
    const signedUrl = await getPresignedUrl({
      fileName: file.name,
      fileType: file.type,
      path: path,
    });

    if (!signedUrl) {
      throw new Error("Failed to obtain signed URL");
    }

    const response = await axios.put(signedUrl.url, file, {
      headers: {
        "Content-Type": file.type,
        "x-amz-acl": "public-read",
      },
    });

    if (response.status === 200) {
      const fileUrl = signedUrl.url.split("?")[0];
      return fileUrl;
    }

    throw new Error(`Upload failed with status: ${response.status}`);
  } catch (error) {
    console.error("Error in uploadFileToDigitalOcean:", error.message);
    throw error;
  }
};

const deleteFileFromDigitalOcean = async (fileUrl, path) => {
  if (!fileUrl) {
    throw new Error("No file URL provided");
  }

  try {
    const signedUrl = await getPresignedUrlForDelete({
      fileUrl,
      path,
    });

    if (!signedUrl) {
      throw new Error("Failed to obtain signed DELETE URL");
    }

    const response = await axios.delete(signedUrl.url);

    if (response.status === 204) {
      console.log("File deleted successfully:", fileUrl);
      return true;
    }

    throw new Error(`Delete failed with status: ${response.status}`);
  } catch (error) {
    console.error("Error in deleteFileFromDigitalOcean:", error.message);
    throw error;
  }
};

export { deleteFileFromDigitalOcean, uploadFileToDigitalOcean };
